import PermissionsApi from "@/api/permissions.api";

const state = () => ({
    loading: false,
    items: []
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    }
}

const actions = {
    async load({ commit, state }) {

        if (state.loading)
            return

        commit('START_LOADING')

        await PermissionsApi.getPermissions().then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}