import httpClient from "./http.api";

const api = {
    getDeparturePoints() {
        return httpClient.post('departures/getDeparturePoints')
    },
    getDestinationPoints(data) {
        return httpClient.post('departures/getDestinationPoints', data)
    },
    getDeparturesByDate(data) {
        return httpClient.post('departures/get-departures-by-date', data)
    },
    getAllPoints(data) {
        return httpClient.post('departures/get-points', data)
    },
    getById(id, from, to) {
        return httpClient.get('departures/get-by-id', {
            params: { id, from, to }
        })
    }
};

export default api;