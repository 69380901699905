const state = () => ({
    token: null
});

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}