import httpClient from "./http.api";

const api = {
    getAll(params) {
        return httpClient.get('events/get-all', {
            params
        })
    },
    confirmEvent(id) {
        return httpClient.post('events/confirm-event', {
            id
        })
    }
}

export default api;