import OrdersApi from "@/api/orders.api";

const state = () => ({
    orders: [],
    loading: false
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true
    },
    STOP_LOADING(state) {
        state.loading = false
    },
    SET_ITEMS(state, payload) {
        state.orders = payload
    }
}

const actions = {
    load({ commit }) {
        commit('START_LOADING')
        OrdersApi.myPreparedBookings().then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}