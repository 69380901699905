import httpClient from "./http.api";

const api = {
    create(form) {
        return httpClient.post('periodic-trip/create', form)
    },
    update(form) {
        return httpClient.post('periodic-trip/update', form)
    },
    remove(form) {
        return httpClient.post('periodic-trip/remove', form)
    },
    activate(form) {
        return httpClient.post('periodic-trip/activate', form)
    },
    getAll(params = {}) {
        return httpClient.get('periodic-trip/get-all', {
            params
        })
    },
    get(id) {
        return httpClient.get('periodic-trip/get', { params: { id }})
    },
};

export default api;