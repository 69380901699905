import httpClient from "./http.api";

const api = {
    getAll() {
        return httpClient.post('route/get-all')
    },
    create(form) {
        return httpClient.post('route/create', form)
    },
    update(form) {
        return httpClient.post('route/update', form)
    },
    remove(form) {
        return httpClient.post('route/remove', form)
    }
};

export default api;