import VehiclesApi from "@/api/vehicles.api";

const state = () => ({
    loading: false,
    carrierId: null,
    items: []
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_CARRIER_ID(state, payload) {
        state.carrierId = payload;
    }
}

const actions = {
    load({ commit }, { carrierId }) {

        commit('SET_CARRIER_ID', carrierId)

        if (!carrierId) {
            commit('SET_ITEMS', []);
            return
        }

        commit('START_LOADING')
        VehiclesApi.getSchedule({
            carrierId
        }).then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data);
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}