import AuthApi from "@/api/auth.api";

const state = () => ({
    isAuthorized: false
});

const getters = {
}

const mutations = {
    IS_AUTHORIZED(state) {
        state.isAuthorized = true;
    },
    IS_UNAUTHORIZED(state) {
        state.isAuthorized = false;
    }
}

const actions = {
    async login({ commit, dispatch }, user) {
        dispatch('App/startLoad', {}, { root: true });
        return AuthApi.login(user.login, user.password).then(response => {
            if (response.success) {
                commit('IS_AUTHORIZED');
                commit('CLOSE_LOGIN_MODAL');
                return Promise.resolve();
            }
        }).finally(() => {
            dispatch('App/stopLoad', {}, { root: true });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}