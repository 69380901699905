import Cookies from 'js-cookie'
import httpClient from "./http.api"

const api = {
    isLoggedIn() {
        return !!Cookies.get('accessToken')
    },
    getToken() {
        return Cookies.get('accessToken')
    },
    async login(login, password) {
        try {
            const authResponse = await httpClient.post('auth',{ login, password })

            if (authResponse.status === 200) {
                Cookies.set('accessToken', authResponse.data.token)
            }

            return { success: true, token: authResponse.data.token }
        } catch (e) {
            Cookies.remove('accessToken')
            throw new Error(e.response.data.message)
        }
    },
    logout() {
        Cookies.remove('accessToken')
        window.location.href = '/'
        // router.push('/login')
    },
    checkAuth() {
        return httpClient.get('check-auth')
    }
}

export default api