import TripsApi from "@/api/trips.api";
import moment from "moment";

const state = () => ({
    loading: false,
    page: 1,
    maxPages: 1,
    items: [],
    filters: {
        carrierId: null,
        routeId: null,
        dateFrom: null,
        dateTo: moment().format('YYYY-MM-DD'),
        time: null
    },
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_FILTER_BY_KEY(state, { key, value }) {
        let data = {  }
        data[key] = value
        state.filters = { ...state.filters, ...data }
    },
    SET_FILTER(state, payload) {
        state.filters.carrierId = payload.carrierId
        state.filters.routeId = payload.routeId
        state.filters.dateFrom = payload.dateFrom
        state.filters.dateTo = payload.dateTo
        state.filters.time = payload.time
    },
    SET_MAX_PAGES(state, payload) {
        state.maxPages = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    }
}

const actions = {
    load({ commit, state }, { page = 1 }) {

        if (state.loading)
            return

        commit('START_LOADING')
        commit('SET_PAGE', page)
        TripsApi.getAll({
            page: page,
            ...state.filters
        }).then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data.data)
                commit('SET_MAX_PAGES', response.data.maxPages)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    },
    create({ dispatch }, form) {
        return TripsApi.create(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    update({ dispatch }, form) {
        return TripsApi.update(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    remove({ dispatch }, tripId) {
        return TripsApi.remove({ tripId }).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    start({ dispatch }, form) {
        return TripsApi.start(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    finish({ dispatch }, form) {
        return TripsApi.finish(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}