import httpClient from './http.api';
import authApi from "@/api/auth.api";

function setInterceptors(hooks) {

    const authInterceptor = config => {
        if (hooks.onRequest)
            hooks.onRequest();

        const token = authApi.getToken();

        config.headers.Authorization = `Bearer ${token}`;
        return config;
    };

    httpClient.interceptors.request.use(authInterceptor);

    httpClient.interceptors.response.use(
        response => {
            if (hooks.onFulfilled)
                hooks.onFulfilled();

            return response;
        },
        error => {
            if (hooks.onError)
                hooks.onError(error);

            if (error.response.status === 401) {
                if (hooks.onUnauthorized)
                    hooks.onUnauthorized();
            }

            return Promise.reject(error);
        }
    );
}

export default setInterceptors;