import httpClient from "./http.api";

const api = {
    getAll() {
        return httpClient.post('bus/get-all')
    },
    getSchedule(form) {
        return httpClient.post('bus/get-schedule', form)
    },
    create(form) {
        return httpClient.post('bus/create', form)
    },
    update(form) {
        return httpClient.post('bus/update', form)
    },
    remove(form) {
        return httpClient.post('bus/remove', form)
    }
};

export default api;