import httpClient from "./http.api";

const api = {
    getAll(params = {}) {
        return httpClient.get('order/get-all', {
            params
        })
    },
    getOne(params = {}) {
        return httpClient.get('order/get-one', {
            params
        })
    },
    book(form) {
        return httpClient.post('order/book', form)
    },
    removeTicket(form) {
        return httpClient.post('order/remove-ticket', form)
    },
    addTicket(form) {
        return httpClient.post('order/add-ticket', form)
    },
    updateTicket(form) {
        return httpClient.post('order/update-ticket', form)
    },
    getMyBookings() {
        return httpClient.get('order/get-my-bookings')
    },
    getMyRefunds() {
        return httpClient.get('order/get-my-refunds')
    },
    acceptBooking(form) {
        return httpClient.post('order/accept-booking', form)
    },
    declineBooking(form) {
        return httpClient.post('order/decline-booking', form)
    },
    myPreparedBookings() {
        return httpClient.get('order/get-my-prepared-bookings')
    },
    mySales() {
        return httpClient.get('order/get-my-sales')
    },
    mySale(form) {
        return httpClient.post('order/get-my-sale', form)
    },
    myPreparedBooking(form) {
        return httpClient.post('order/get-my-prepared-booking', form)
    },
    orderPayWithCash(form) {
        return httpClient.post('order/pay-with-cash', form)
    },
    refund(form) {
        return httpClient.post('order/refund', form)
    },
    editComment(form) {
        return httpClient.post('order/edit-comment', form)
    },
    getMyAllBooks(params = {}) {
        return httpClient.get('order/get-my-all-books', {
            params
        })
    },
};

export default api;