import httpClient from "./http.api";

const api = {
    getDirectionPrice(form) {
        return httpClient.post('prices/get-price-by-direction', form)
    },
    getPrices() {
        return httpClient.get('prices/get-prices')
    },
    saveDirectionPrice(form) {
        return httpClient.post('prices/save-price-by-direction', form)
    },
    saveServiceFee(form) {
        return httpClient.post('prices/save-service-fee', form)
    },
    saveCashBoxFee(form) {
        return httpClient.post('prices/save-cash-box-fee', form)
    },
    saveChildTicketDiscount(form) {
        return httpClient.post('prices/save-child-ticket-discount', form)
    }
};

export default api;