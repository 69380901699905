<template>
  <div>
    <b-overlay :show="loadingStatus" fixed no-wrap z-index="9999">
    </b-overlay>
      <div id="main-wrapper">
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Авторизация"
            :no-close-on-backdrop="true"
            hide-header-close
            hide-footer
            size="sm"
        >
          <b-overlay :show="loading">
            <b-form @submit.stop.prevent="submit()">
              <b-form-group class="mb-4">
                <b-form-input id="email" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true"/>
                <div class="invalid-feedback">Не корректный Email!</div>
              </b-form-group>
              <b-form-group class="mb-4">
                <b-form-input id="password" type="password" v-model="password" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(password) && passwordBlured}" v-on:blur="passwordBlured = true"/>
                <div class="invalid-feedback">Не менее 6-ти символов!</div>
              </b-form-group>
              <b-button variant="primary" @click="submit">Авторизоваться</b-button>
            </b-form>
          </b-overlay>
        </b-modal>
        <router-view/>
      </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import Cookies from "js-cookie";
import AuthApi from "@/api/auth.api";

export default {
  name: 'App',
  watch: {
      isAuthorized(value) {
        if (value) {
          AuthApi.checkAuth().then(async () => {
            await this.$store.dispatch('Permissions/load');
            await this.$store.dispatch('init');
          }).catch((error) => {
            if (error.response && error.response.status === 401) {
              Cookies.remove('accessToken')
              window.location.href = '/'
            }
          })
        }
      }
  },
  created() {
    const token = Cookies.get('accessToken');
    if (token) this.$store.commit('Auth/IS_AUTHORIZED');
  },
  data() {
    return {
      loading: false,
      email: '',
      emailBlured: false,
      password: '',
      passwordBlured: false,
    }
  },
  computed: {
    ...mapState({
      loadingStatus: state => state.App.globalLoading,
      // showLoginModal: state => state.Auth.showLoginModal,
      isAuthorized: state => state.Auth.isAuthorized,
    })
  },
  methods: {
    validate() {
      this.emailBlured = true;
      this.passwordBlured = true;
      if (this.validEmail(this.email) && this.validPassword(this.password)) {
        this.valid = true;
      }
    },
    validEmail(email) {
      let re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (re.test(email.toLowerCase())) {
        return true;
      }
    },
    validPassword(password) {
      if (password.length > 5) {
        return true;
      }
    },
    submit() {
      console.log('test');
      this.validate();
      if (!this.valid)
        return;

      this.$store.dispatch('Auth/login', {
        login: this.email,
        password: this.password
      }).then(() => {
        this.$bvToast.toast('Вы успешно авторизовались', {
          title: 'Успех',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: true
        });
      });
    }
  }
}
</script>

<style>

</style>
