import OrdersApi from "@/api/orders.api";
import moment from "moment";

const state = () => ({
    loading: false,
    page: 1,
    maxPages: 1,
    items: [],
    filters: {
        carrierId: null,
        routeId: null,
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: null,
        time: null
    },
    search: {
        orderNumber: null,
        clientPhone: null,
        clientLastName: null
    }
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_MAX_PAGES(state, payload) {
        state.maxPages = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_FILTER_BY_KEY(state, { key, value }) {
        let data = {  }
        data[key] = value
        state.filters = { ...state.filters, ...data }
    },
    SET_FILTER(state, payload) {
        state.filters.carrierId = payload.carrierId
        state.filters.routeId = payload.routeId
        state.filters.dateFrom = payload.dateFrom
        state.filters.dateTo = payload.dateTo
        state.filters.time = payload.time
    },
    SET_SEARCH(state, payload) {
        state.filters.orderNumber = payload.orderNumber
        state.filters.clientPhone = payload.clientPhone
        state.filters.clientLastName = payload.clientLastName
    },
    SET_SEARCH_BY_KEY(state, { key, value }) {
        let data = {  }
        data[key] = value
        state.search = { ...state.search, ...data }
    },
}

const actions = {
    load({ commit, state }, { page = 1}) {

        if (state.loading)
            return

        commit('START_LOADING')
        commit('SET_PAGE', page)

        OrdersApi.getMyAllBooks({
            page,
            ...state.filters
        }).then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data.data)
                commit('SET_MAX_PAGES', response.data.maxPages)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}