import axios from 'axios';

const URL = process.env.VUE_APP_API_URL;

let config = {
    baseURL: `${URL}/`,
    // timeout: 15000
};

const httpClient = axios.create(config);

export default httpClient;