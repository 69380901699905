import PricesApi from "@/api/prices.api";

const state = () => ({
    loadingDirection: false,
    fromId: null,
    toId: null,
    directionPrice: null,

    loading: false,
    cashBoxFee: null,
    serviceFee: null,
    childrenTicketDiscount: null
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    START_LOADING_DIRECTION(state) {
        state.loadingDirection = true;
    },
    STOP_LOADING_DIRECTION(state) {
        state.loadingDirection = false;
    },
    SET_PRICES(state, payload) {
        state.cashBoxFee = payload.cashBoxFee
        state.serviceFee = payload.serviceFee
        state.childrenTicketDiscount = payload.childrenTicketDiscount
    },
    SET_DIRECTION_PRICE(state, payload) {
        state.directionPrice = payload
    }
}

const actions = {
    load({ commit }) {
        commit('START_LOADING')
        PricesApi.getPrices().then(response => {
            if (response.data.status === 'success')
                commit('SET_PRICES', response.data)
        }).finally(() => {
            commit('STOP_LOADING')
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}