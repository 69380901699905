import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex);

import App from "@/store/modules/App"
import Auth from "@/store/modules/Auth"
import Init from "@/store/modules/Init"
import Departures from "@/store/modules/Departures"
import Carriers from "@/store/modules/Carriers"
import Drivers from "@/store/modules/Drivers"
import Routes from "@/store/modules/Routes"
import Vehicles from "@/store/modules/Vehicles"
import Countries from "@/store/modules/Countries"
import VehicleSchemas from "@/store/modules/VehicleSchemas"
import DocumentTypes from "@/store/modules/DocumentTypes"
import Trips from "@/store/modules/Trips"
import PeriodicTrips from "@/store/modules/PeriodicTrips"
import VehiclesSchedule from "@/store/modules/VehiclesSchedule";
import Orders from "@/store/modules/Orders";
import MyAllBooks from "@/store/modules/MyAllBooks";
import MyBooks from '@/store/modules/MyBooks';
import MyRefunds from '@/store/modules/MyRefunds';
import MyPreparedBookings from '@/store/modules/MyPreparedBookings';
import MySales from '@/store/modules/MySales';
import News from '@/store/modules/News';
import Prices from '@/store/modules/Prices';
import HelpfulInformation from '@/store/modules/HelpfulInformation';
import Stations from '@/store/modules/Stations';
import PopularDirections from '@/store/modules/PopularDirections';
import Faq from '@/store/modules/Faq';
import Users from '@/store/modules/Users';
import Permissions from '@/store/modules/Permissions';
import Events from '@/store/modules/Events';
import Promotions from '@/store/modules/Promotions';

export default new Vuex.Store({
  state: {
    Sidebar_drawer: false,
    LayoutType: "full-sidebar",
    SidebarColor: "white",
    navbarColor: "primary",
    logoColor: "white",
  },
  mutations: {
    SET_LAYOUT_TYPE(state, payload) {
      state.LayoutType = payload
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload
    },
  },
  actions: {
    init({ dispatch, state }) {
      if (state.Promotions.items.includes('promotions')) {
        dispatch('Promotions/load', { page: 1 })
      }

      if (state.Permissions.items.includes('stations')) {
        dispatch('Stations/load', { page: 1 })
      }

      if (state.Permissions.items.includes('users')) {
        dispatch('Users/load', { page: 1 })
      }

      if (state.Permissions.items.includes('carrier')) {
        dispatch('Carriers/load')
      }

      if (state.Permissions.items.includes('driver')) {
        dispatch('Drivers/load')
      }

      if (state.Permissions.items.includes('bus')) {
        dispatch('Vehicles/load')
      }

      if (state.Permissions.items.includes('route')) {
        dispatch('Routes/load')
      }

      if (state.Permissions.items.includes('countries')) {
        dispatch('Countries/load')
      }

      if (state.Permissions.items.includes('document-types')) {
        dispatch('DocumentTypes/load')
      }

      if (state.Permissions.items.includes('bus-schema')) {
        dispatch('VehicleSchemas/load')
      }
    },
    setLayoutType({ commit }, width) {
      commit("SET_LAYOUT_TYPE", width)
    },
  },
  getters: {},
  modules: {
    App,
    Auth,
    Init,
    Departures,
    MySales,
    Carriers,
    Drivers,
    Routes,
    Vehicles,
    VehicleSchemas,
    Countries,
    DocumentTypes,
    Trips,
    PeriodicTrips,
    VehiclesSchedule,
    Orders,
    MyRefunds,
    MyBooks,
    MyPreparedBookings,
    MyAllBooks,
    News,
    Prices,
    HelpfulInformation,
    Stations,
    PopularDirections,
    Faq,
    Users,
    Permissions,
    Events,
    Promotions
  }
})
