import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/vue-loading-overlay";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import setInterceptors from "@/api/interceptors";
import Cookies from "js-cookie";

Vue.config.productionTip = false;

new Vue({
    created() {
        setInterceptors({
            onError: (error) => {
                if (error.response && error.response.data.message) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Ошибка',
                        autoHideDelay: 7000,
                        variant: 'danger',
                        appendToast: true
                    });
                } else if (error.message) {
                    this.$bvToast.toast(error.message, {
                        title: 'Ошибка',
                        autoHideDelay: 7000,
                        variant: 'danger',
                        appendToast: true
                    });
                }
            },
            onUnauthorized: () => {
                Cookies.remove('accessToken');
                // this.$store.commit('Auth/OPEN_LOGIN_MODAL');
            }
        });
    },
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");