import httpClient from "./http.api";

const api = {
    getAll() {
        return httpClient.post('driver/get-all')
    },
    getOne(form) {
        return httpClient.post('driver/get-one', form)
    },
    create(form) {
        return httpClient.post('driver/create', form)
    },
    update(form) {
        return httpClient.post('driver/update', form)
    },
    remove(form) {
        return httpClient.post('driver/remove', form)
    }
};

export default api;