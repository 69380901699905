import httpClient from "./http.api";

const api = {
    getAll() {
        return httpClient.get('popular-direction/get-all')
    },
    getOne(form) {
        return httpClient.post('popular-direction/get-one', form)
    },
    create(form) {
        return httpClient.post('popular-direction/create', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    update(form) {
        return httpClient.post('popular-direction/update', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    remove(form) {
        return httpClient.post('popular-direction/remove', form)
    }
};

export default api;