import PeriodicTripsApi from "@/api/periodicTrips.api";

const state = () => ({
    loading: false,
    page: 1,
    maxPages: 1,
    items: []
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_MAX_PAGES(state, payload) {
        state.maxPages = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    }
}

const actions = {
    load({ commit }, { page = 1 }) {
        commit('START_LOADING')
        commit('SET_PAGE', page)
        PeriodicTripsApi.getAll({
            page: page
        }).then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data.data)
                commit('SET_MAX_PAGES', response.data.maxPages)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    },
    create({ dispatch }, form) {
        return PeriodicTripsApi.create(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    update({ dispatch }, form) {
        return PeriodicTripsApi.update(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    remove({ dispatch }, id) {
        return PeriodicTripsApi.remove({ id }).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
        });
    },
    activate({ dispatch }, id) {
        return PeriodicTripsApi.activate({ id }).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1 })
                return Promise.resolve();
            }
    });
}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}