import httpClient from "./http.api";

const api = {
    getAll() {
        return httpClient.get('helpful-information/get-all')
    },
    getOne(form) {
        return httpClient.post('helpful-information/get-one', form)
    },
    create(form) {
        return httpClient.post('helpful-information/create', form)
    },
    update(form) {
        return httpClient.post('helpful-information/update', form)
    },
    remove(form) {
        return httpClient.post('helpful-information/remove', form)
    }
};

export default api;