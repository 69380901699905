import NewsApi from "@/api/news.api";

const state = () => ({
    loading: false,
    page: 1,
    maxPages: 1,
    items: []
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        console.log('news')
        console.log(payload)
        state.items = payload
    },
    SET_MAX_PAGES(state, payload) {
        state.maxPages = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    }
}

const actions = {
    async load({ commit, state }, { page = 1}) {

        if (state.loading)
            return

        commit('START_LOADING')
        commit('SET_PAGE', page)

        NewsApi.getAll({ page }).then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data.data)
                commit('SET_MAX_PAGES', response.data.maxPages)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    },
    create({ dispatch }, form) {
        console.log('form')
        console.log(form)
        return NewsApi.create(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1})
                return Promise.resolve();
            }
        });
    },
    update({ dispatch }, form) {
        return NewsApi.update(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1})
                return Promise.resolve();
            }
        });
    },
    remove({ dispatch }, id) {
        return NewsApi.remove({ id }).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load', { page: 1})
                return Promise.resolve();
            }
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}