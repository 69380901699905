import dictionariesApi from "@/api/dictionaries.api";

const state = () => ({
    items: [],
    loading: false,
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_COUNTRIES(state, payload) {
        state.items = payload;
    }
}

const actions = {
    load({ commit }) {
        commit('STOP_LOADING');
        dictionariesApi.getCountries().then(response => {
            console.log(response.data);
            if (response.data)
                commit('SET_COUNTRIES', response.data.data);
        }).finally(() => {
            commit('STOP_LOADING');
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}