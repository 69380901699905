import DeparturesApi from "@/api/departures.api"

const SEARCH_STATUSES = {
    INIT: 'INIT',
    EMPTY: 'EMPTY',
    FOUNDED: 'FOUNDED'
}

const state = () => ({
    loading: false,
    searchStatus: SEARCH_STATUSES.INIT,
    departures: [],
    destinations: [],
    trips: [],
    filters: {
        date: null,
        departurePoint: null,
        destinationPoint: null
    },
    fromFilter: null
})

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true
    },
    STOP_LOADING(state) {
        state.loading = false
    },
    SET_DEPARTURES(state, departures) {
        state.departures = departures
    },
    SET_DESTINATIONS(state, destinations) {
        state.destinations = destinations
    },
    SET_SEARCH_STATUS(state, payload) {
        state.searchStatus = payload
    },
    SET_TRIPS(state, trips) {
        state.trips = trips
    },
    SET_FILTER_BY_KEY(state, { key, value }) {
        let data = {  }
        data[key] = value
        state.filters = { ...state.filters, ...data }
    },
    SET_FROM_FILTER(state, payload) {
        state.fromFilter = payload;
    },
    CLEAR_FILTER(state) {
        let filters = state.filters
        for (let key in filters) {
            filters[key] = null
        }

        state.filters = { ...filters }
    }
}

const actions = {
    init({ dispatch }) {
        dispatch('loadDepartures')
    },
    loadDepartures({ commit }) {
        commit('START_LOADING')
        DeparturesApi.getDeparturePoints().then(response => {
            if (response.data.status === 'success') {
                commit('SET_DEPARTURES', response.data.data)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        })
    },
    loadDestinations({ commit, dispatch }, departureId) {
        if (!departureId) {
            dispatch('clearDestinations')
            return;
        }
        commit('SET_FILTER_BY_KEY', { key: 'departurePoint', value: departureId})
        commit('START_LOADING')
        DeparturesApi.getDestinationPoints({ pointId: departureId }).then(response => {
            if (response.data.status === 'success') {
                console.log(response.data.data)
                commit('SET_DESTINATIONS', response.data.data)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        })
    },
    async loadTrips({ commit, dispatch, state }) {
        commit('START_LOADING')
        dispatch('clearTrips')

        const filter = {
            fromPointId: state.filters.departurePoint,
            toPointId: state.filters.destinationPoint,
            day: state.filters.date
        }

        return await DeparturesApi.getDeparturesByDate(filter).then(response => {
            if (response.data.status === 'success') {
                console.log('trips')
                console.log(response.data.data)
                commit('SET_TRIPS', response.data.data.map(data => ({ ...data, fromFilter: filter })))
                commit('SET_FROM_FILTER', filter)

                if (response.data.data.length === 0) {
                    commit('SET_SEARCH_STATUS', SEARCH_STATUSES.EMPTY)
                } else {
                    commit('SET_SEARCH_STATUS', SEARCH_STATUSES.FOUNDED)
                }
            }
        }).finally(() => {
            commit('STOP_LOADING')
        })
    },
    actualizeTrips({ commit, state }) {
        if (!state.fromFilter)
            return

        console.log('actualization.....')

        commit('START_LOADING')

        DeparturesApi.getDeparturesByDate({ ...state.fromFilter }).then(response => {
            if (response.data.status === 'success') {
                commit('SET_TRIPS', response.data.data.map(data => ({ ...data, fromFilter: state.fromFilter })))

                if (response.data.data.length === 0) {
                    commit('SET_SEARCH_STATUS', SEARCH_STATUSES.EMPTY)
                } else {
                    commit('SET_SEARCH_STATUS', SEARCH_STATUSES.FOUNDED)
                }
            }
        }).finally(() => {
            commit('STOP_LOADING')
        })
    },
    clearTrips({ commit }) {
        commit('SET_TRIPS', [])
        commit('SET_FROM_FILTER', null)
        commit('SET_SEARCH_STATUS', SEARCH_STATUSES.INIT)
    },
    clearDestinations({ commit }) {
        commit('CLEAR_FILTER')
        commit('SET_DESTINATIONS', [])
        commit('SET_SEARCH_STATUS', SEARCH_STATUSES.INIT)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}