import httpClient from "./http.api";

const api = {
    getAll(params = {}) {
        return httpClient.get('carrier/get-all', {
            params
        })
    },
    getOne(form) {
        return httpClient.post('carrier/get-one', form)
    },
    create(form) {
        return httpClient.post('carrier/create', form)
    },
    update(form) {
        return httpClient.post('carrier/update', form)
    },
    remove(form) {
        return httpClient.post('carrier/remove', form)
    }
};

export default api;