import RoutesApi from "@/api/routes.api";

const state = () => ({
    loading: false,
    items: []
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    }
}

const actions = {
    load({ commit }) {
        commit('START_LOADING')
        RoutesApi.getAll().then(response => {
            if (response.data) {
                commit('SET_ITEMS', response.data);
            }
        }).finally(() => {
            commit('STOP_LOADING')
        });
    },
    create({ dispatch }, form) {
        return RoutesApi.create(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load')
                return Promise.resolve();
            }
        });
    },
    update({ dispatch }, form) {
        return RoutesApi.update(form).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load')
                return Promise.resolve();
            }
        });
    },
    remove({ dispatch }, id) {
        return RoutesApi.remove({ id }).then((response) => {
            if (response.data.status === 'success') {
                dispatch('load')
                return Promise.resolve();
            }
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}