import httpClient from "./http.api";

const api = {
    create(form) {
        return httpClient.post('trip/create', form)
    },
    update(form) {
        return httpClient.post('trip/update', form)
    },
    remove(form) {
        return httpClient.post('trip/remove', form)
    },
    start(form) {
        return httpClient.post('trip/start', form)
    },
    landing(form) {
        return httpClient.post('trip/landing', form)
    },
    finish(form) {
        return httpClient.post('trip/finish', form)
    },
    edit(form) {
        return httpClient.post('trip/edit', form)
    },
    getAll(params = {}) {
        return httpClient.get('trip/get-all', {
            params
        })
    },
    getCurrentTrips() {
        return httpClient.get('trip/get-current-trips')
    }
};

export default api;