import Vue from "vue";
import Router from "vue-router";
import Auth from '@/api/auth.api';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      name: "login",
      path: "/login",
      component: () => import("@/pages/Login"),
    },
    {
      name: "trip-report",
      path: "/trip-report/:id",
      component: () => import("@/pages/Reports/TripReport")
    },
    {
      name: "information-table",
      path: "/information-table",
      component: () => import("@/pages/OperativeInformation/InformationTable")
    },
    {
      name: "dashboard",
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Dashboard",
          path: "dashboard",
          component: () => import("@/pages/Dashboard"),
        },
        {
          name: "Events",
          path: "/events",
          component: () => import("@/pages/Events"),
          children: [
            {
              name: 'SuspiciousEvents',
              path: "/events/suspicious-events",
              component: () => import("@/pages/Events/SuspiciousEvents"),
            }
          ]
        },
        {
          name: "Orders",
          path: "/orders",
          component: () => import("@/pages/OrdersAndBookings"),
          children: [
            {
              name: 'WorkWithOrders',
              path: "/orders/work",
              component: () => import("@/pages/OrdersAndBookings/WorkWithOrders"),
            },
            {
              name: 'WorkWithOrders',
              path: "/orders/bookings",
              component: () => import("@/pages/OrdersAndBookings/MyBookings"),
            }
          ]
        },
        {
          name: "Flights",
          path: "/flights",
          component: () => import("@/pages/Flights"),
          children: [
            {
              name: 'VehicleSchedule',
              path: "/flights/vehicle-schedule",
              component: () => import("@/pages/Flights/VehicleSchedule"),
            },
            {
              name: 'WorkWithTrips',
              path: "/flights/trips",
              component: () => import("@/pages/Flights/WorkWithTrips"),
            },
            {
              name: 'WorkWithPeriodicTrips',
              path: "/flights/periodic-trips",
              component: () => import("@/pages/Flights/WorkWithPeriodicTrips"),
            },
            {
              name: 'TripsGroupManaging',
              path: "/flights/trips-group-managing",
              component: () => import("@/pages/Flights/TripsGroupManaging"),
            }
          ]
        },
        {
          name: "Dictionaries",
          path: "/dictionaries",
          component: () => import("@/pages/Dictionaries"),
          children: [
            {
              name: 'Prices',
              path: "/dictionaries/prices",
              component: () => import("@/pages/Dictionaries/Prices"),
            },
            {
              name: 'Drivers',
              path: "/dictionaries/drivers",
              component: () => import("@/pages/Dictionaries/Drivers"),
            },
            {
              name: 'VehicleSchemas',
              path: "/dictionaries/vehicle-schemas",
              component: () => import("@/pages/Dictionaries/VehicleSchemas"),
            },
            {
              name: 'Vehicles',
              path: "/dictionaries/vehicles",
              component: () => import("@/pages/Dictionaries/Vehicles"),
            },
            {
              name: 'Carriers',
              path: "carriers",
              component: () => import("@/pages/Dictionaries/Carriers"),
            },
            {
              name: 'Routes',
              path: "/dictionaries/routes",
              component: () => import("@/pages/Dictionaries/Routes"),
            },
            {
              name: 'Users',
              path: "/dictionaries/users",
              component: () => import("@/pages/Dictionaries/Users"),
            }
          ]
        },
        {
          name: "Dispatching",
          path: "/dispatching",
          component: () => import("@/pages/Dispatching"),
          children: [
            {
              name: 'Departures',
              path: "/dispatching/departures",
              component: () => import("@/pages/Dispatching/Departures"),
            },
            {
              name: 'Vehicles',
              path: "/dispatching/driver-departing",
              component: () => import("@/pages/Dispatching/DriverDeparting"),
            }
          ]
        },
        {
          name: "Statics",
          path: "/statistics",
          component: () => import("@/pages/StatisticsAndReports"),
          children: [
            {
              name: 'Report',
              path: "/statistics/sales-and-refunds",
              component: () => import("@/pages/Reports/SalesAndRefunds"),
            },
            {
              name: 'Report',
              path: "/statistics/sales-and-refunds-by-cashiers",
              component: () => import("@/pages/Reports/SalesAndRefundsByCashiers"),
            },
            {
              name: 'Report',
              path: "detailed-statistics-by-sales-and-refunds",
              component: () => import("@/pages/Reports/DetailedStatisticsBySalesAndRefunds"),
            }
          ]
        },
        {
          name: "OperativeInformation",
          path: "/operative-information",
          component: () => import("@/pages/OperativeInformation"),
          children: [
            {
              name: 'SchedulesAndVehicles',
              path: "/operative-information/schedules-and-vehicles",
              component: () => import("@/pages/OperativeInformation/SchedulesAndVehicles"),
            },
            {
              name: 'RequestToAccess',
              path: "/operative-information/request-to-access",
              component: () => import("@/pages/OperativeInformation/RequestToAccess"),
            }
          ]
        },
        {
          name: "Website",
          path: "/website",
          component: () => import("@/pages/Website"),
          children: [
            {
              name: 'News',
              path: "/website/news",
              component: () => import("@/pages/Website/News"),
            },
            {
              name: 'HelpfulInformation',
              path: "/website/helpful-information",
              component: () => import("@/pages/Website/HelpfulInformation"),
            },
            {
              name: 'PopularDirection',
              path: "/website/popular-directions",
              component: () => import("@/pages/Website/PopularDirections"),
            },
            {
              name: 'Stations',
              path: "/website/stations",
              component: () => import("@/pages/Website/Stations"),
            },
            {
              name: 'Faq',
              path: "/website/faq",
              component: () => import("@/pages/Website/Faq"),
            }
          ]
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/pages/Dashboard"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (Auth.isLoggedIn() && to.name === 'login')
    next({name: 'dashboard'});

  if (!Auth.isLoggedIn() && to.name !== 'login')
    next({name: 'login'});
  else next();
});

export default router;