import httpClient from "./http.api";

const api = {
    getAll(params) {
        return httpClient.get('faq/get-all', {
            params
        })
    },
    giveAnAnswer(form) {
        return httpClient.post('faq/give-an-answer', form)
    }
};

export default api;