import ApiOrders from '@/api/orders.api'
import moment from "moment";
import 'moment-timezone';
import 'moment-duration-format';

let bookingTimer = null

const state = () => ({
    loading: false,
    bookings: {}
});

const getters = {}

const mutations = {
    SET_TIMER(state, payload) {
        state.bookings = { ...state.bookings, time: payload }
    },
    START_LOADING(state) {
        state.loading = true
    },
    STOP_LOADING(state) {
        state.loading = false
    },
    CREATE_BOOKING(state, payload) {
        state.bookings = payload;
    },
    ADD_TICKET(state, payload) {
        let bookings = state.bookings
        bookings.tickets = [ ...bookings.tickets, payload ]
        state.bookings = { ...bookings }
    },
    REMOVE_TICKET(state, seatNumber) {
        let bookings = state.bookings
        bookings.tickets = bookings.tickets.filter(ticket => ticket.seatNumber !== seatNumber)

        if (bookings.tickets.length) {
            state.bookings = { ...bookings }
        } else {
            state.bookings = {}
            clearInterval(bookingTimer)
        }
    },
    CLEAR_BOOKINGS(state) {
        state.bookings = {}
        clearInterval(bookingTimer)
    }
}

const actions = {
    declineBooking({ commit, dispatch, state }) {
        if (!state.bookings.id)
            return

        dispatch('App/startLoad', {}, { root: true })

        ApiOrders.declineBooking({ orderId: state.bookings.id }).then(response => {
            if (response.data.status === 'success') {
                commit('CLEAR_BOOKINGS')
                return response.data
            }
        }).finally(() => {
            dispatch('App/stopLoad', {}, { root: true })
        })
    },
    acceptBooking({ commit, dispatch, state }, form) {
        dispatch('App/startLoad', {}, { root: true })

        const bookingId = state.bookings.id

        return ApiOrders.acceptBooking({ orderId: state.bookings.id, ...form}).then(response => {
            if (response.data.status === 'success') {
                commit('CLEAR_BOOKINGS')

                dispatch('MyPreparedBookings/load', {}, { root: true })
                dispatch('Departures/actualizeTrips', {}, { root: true })

                return bookingId
            }
        }).finally(() => {
            dispatch('App/stopLoad', {}, { root: true })
        })
    },
    startTimer({ commit }, expiredAt) {
        let seconds = moment.tz(expiredAt, process.env.VUE_APP_TZ).diff(moment.tz(process.env.VUE_APP_TZ), 'seconds')
        console.log(seconds)
        bookingTimer = setInterval(() => {
            seconds -= 1
            if (seconds <= 0) {
                commit('CLEAR_BOOKINGS')
            } else {
                commit('SET_TIMER', seconds)
            }
        }, 1000)
    },
    load({ dispatch, commit }) {
        commit('START_LOADING')
        ApiOrders.getMyBookings().then(response => {
            console.log(response.data)
            if (response.data && response.data.order) {
                dispatch('startTimer', response.data.order.expiredAt)
                commit('CREATE_BOOKING', response.data.order)
            }
        }).finally(() => {
            commit('STOP_LOADING')
        })
    },
    updateTicket({ dispatch, state }, form) {
        dispatch('App/startLoad', {}, { root: true })

        ApiOrders.updateTicket({ ...form, orderId: state.bookings.id, seatNumber: 1 }).then(() => {
            return dispatch('load')
        }).finally(() => {
            dispatch('App/stopLoad', {}, { root: true })
        })
    },
    book({ commit, dispatch, state }, form) {
        dispatch('App/startLoad', {}, { root: true })

        if (state.bookings.tripId && (state.bookings.fromId !== form.fromId || state.bookings.toId !== form.toId || state.bookings.tripId !== form.tripId)) {
            dispatch('App/stopLoad', {}, { root: true })
            return Promise.reject({
                title: 'Есть незакрытые брони',
                message: 'Перед тем как начать создавать новые брони, необходимо закрыть предыдущую.',
                variant: 'danger'
            })
        }

        if (state.bookings.tickets && state.bookings.tickets.some(ticket => ticket.seatNumber === form.seatNumber)) {
            form = { ...form, orderId: state.bookings.id };
            return ApiOrders.removeTicket(form).then(() => {
                commit('REMOVE_TICKET', form.seatNumber)
            }).finally(() => {
                dispatch('App/stopLoad', {}, { root: true })
            })
        }

        if (!state.bookings.tickets) {
            return ApiOrders.book(form).then((response) => {
                if (response.data.status) {
                    dispatch('startTimer', response.data.order.expiredAt)
                    commit('CREATE_BOOKING', response.data.order)
                    return response.data.order.tickets[0]
                }
            }).finally(() => {
                dispatch('App/stopLoad', {}, { root: true })
            })
        } else {
            return ApiOrders.addTicket( { ...form, orderId: state.bookings.id }).then((response) => {
                if (response.data.status) {
                    commit('ADD_TICKET', response.data.ticket)
                    return response.data.ticket
                }
            }).finally(() => {
                dispatch('App/stopLoad', {}, { root: true })
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}