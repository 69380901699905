const state = () => ({
    globalLoading: false
});

const getters = {}

const mutations = {
    START_LOADING(state) {
        state.globalLoading = true;
    },
    STOP_LOADING(state) {
        state.globalLoading = false;
    },
}

const actions = {
    startLoad({ commit }) {
        commit('START_LOADING');
    },
    stopLoad({ commit }) {
        commit('STOP_LOADING');
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}