import httpClient from "./http.api";

const api = {
    getDocumentTypes() {
        return httpClient.post('document-types/getAll')
    },
    getCountries() {
        return httpClient.post('countries/getAll')
    },
    getTimezones() {
        return httpClient.post('dictionary/timezones')
    }
};

export default api;